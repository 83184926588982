import React from "react";
import {
  Box,
  Container,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import { Height, Repeat } from "@mui/icons-material";
const ImageGallery = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#e1e8cf",
          backgroundPosition: "center",
        }}
      >
        <Typography variant="h4" color="brown" textAlign="center" gutterBottom>
          Gallery
        </Typography>
        <Container>
          <ImageList
            gap={4}
            sx={{
              mb: 8,
              gridTemplateColumns:
                "repeat(auto-fill,minmax(280px, 1fr))!important",
            }}
          >
            {itemData.map((item) => (
              <ImageListItem key={item.img} sx={{ height: "100%" }}>
                <img
                  //src={require("../../images/events/events_2.png")}
                  //src={require(`../../images/events/${item.img}.png?w=164&h=164&fit=crop&auto=format`)}
                  srcSet={require(`../../images/gallery/${item.img}.JPG?w=164&h=164&fit=crop&auto=format&dpr=2 2x`)}
                  //src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                  //srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Container>
      </Box>
    </>
  );
};

export default ImageGallery;

const itemData = [
  {
    img: "DSC04511",
    title: "No Title",
  },
  {
    img: "DSC04510",
    title: "No Title",
  },
  {
    img: "DSC04512",
    title: "No Title",
  },
  {
    img: "DSC04857",
    title: "No Title",
  },
  {
    img: "DSC05190",
    title: "No Title",
  },
  {
    img: "DSC04571",
    title: "No Title",
  },
  {
    img: "DSC04836",
    title: "No Title",
  },
  {
    img: "DSC04840",
    title: "No Title",
  },

  {
    img: "DSC05273",
    title: "No Title",
  },
  {
    img: "DSC05692",
    title: "No Title",
  },
  {
    img: "DSC05773",
    title: "No Title",
  },
  {
    img: "DSC05678",
    title: "No Title",
  },

  {
    img: "DSC05798",
    title: "No Title",
  },
  {
    img: "DSC05037",
    title: "No Title",
  },

  {
    img: "DSC04730",
    title: "Breakfast",
  },

  {
    img: "DSC05246",
    title: "Breakfast",
  },
  {
    img: "DSC05660",
    title: "Breakfast",
  },
];
