import React from "react";
import NavbarHome from "../../components/navbar/NavbarHome";
import { Box, Typography } from "@mui/material";
import UpcomingEvent1 from "../../images/events/upcomingEvent1.jpeg";
const UpcomingEvents = () => {
  return (
    <>
      <NavbarHome />
      <Box
        sx={{
          backgroundColor: "#e1e8cf",
          backgroundPosition: "center",
        }}
      >
        <Typography variant="h4" color="brown" textAlign="center" gutterBottom>
          Upcoming Events
        </Typography>
        <Box
          sx={{
            backgroundImage: `url(${UpcomingEvent1})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "black",
            backgroundPosition: "center",
            backgroundSize: "cover",
            //height: { xs: 500, sm: 900, md: 1200, lg: 1600 },
            height: { xs: 400, sm: 900, md: 1200, lg: 1600 },
            //height: 1600,
            //width: { xs: "60%", sm: "70%" },
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        ></Box>
      </Box>
    </>
  );
};

export default UpcomingEvents;
