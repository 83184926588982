import React from "react";
import NavbarHome from "../../components/navbar/NavbarHome";
import { Box, Stack, Typography } from "@mui/material";
import { Call, Email } from "@mui/icons-material";

const ContactUs = () => {
  return (
    <>
      <NavbarHome />
      <Box
        sx={{
          backgroundColor: "#e1e8cf",
          backgroundPosition: "center",
        }}
      >
        <Typography variant="h4" color="brown" textAlign="center" gutterBottom>
          Contact Us
        </Typography>
        <Box>
          <Stack direction="column" textAlign="center">
            <Typography variant="h5">
              <Call fontSize="medium" />
              &nbsp; 226-224-4091/226-926-0710
            </Typography>
            <Typography variant="h5">
              <Email fontSize="medium" />
              &nbsp;utsavontario@gamil.com
            </Typography>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default ContactUs;
